// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(auth)/_layout.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/(auth)/_layout.tsx"); } },
  "./(auth)/embed.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/(auth)/embed.tsx"); } },
  "./(auth)/login.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/(auth)/login.tsx"); } },
  "./(auth)/review.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/(auth)/review.tsx"); } },
  "./(auth)/settings.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/(auth)/settings.tsx"); } },
  "./(index)/_layout.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/(index)/_layout.tsx"); } },
  "./(index)/_layout.web.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/(index)/_layout.web.tsx"); } },
  "./(index)/index.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/(index)/index.tsx"); } },
  "./(index)/index.web.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/(index)/index.web.tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/_layout.tsx"); } },
  "./download.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/download.tsx"); } },
  "./learnMore.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/learnMore.tsx"); } },
  "./terms.tsx": { enumerable: true, get() { return import("/Users/andym/Development/Active/orbit/packages/app/src/app/terms.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;